/**
 * @fileoverview gRPC-Web generated client stub for currency
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/currency/currency.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_currency_currency_pb from '../../protobuf/currency/currency_pb';
import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';


export class CurrencyServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/currency.CurrencyService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_currency_currency_pb.CreateCurrencyRequest,
    protobuf_model_merged_pb.CurrencyResponse,
    (request: protobuf_currency_currency_pb.CreateCurrencyRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CurrencyResponse.deserializeBinary
  );

  create(
    request: protobuf_currency_currency_pb.CreateCurrencyRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CurrencyResponse>;

  create(
    request: protobuf_currency_currency_pb.CreateCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CurrencyResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CurrencyResponse>;

  create(
    request: protobuf_currency_currency_pb.CreateCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CurrencyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency.CurrencyService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency.CurrencyService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/currency.CurrencyService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_currency_currency_pb.UpdateCurrencyRequest,
    protobuf_model_merged_pb.CurrencyResponse,
    (request: protobuf_currency_currency_pb.UpdateCurrencyRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.CurrencyResponse.deserializeBinary
  );

  update(
    request: protobuf_currency_currency_pb.UpdateCurrencyRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.CurrencyResponse>;

  update(
    request: protobuf_currency_currency_pb.UpdateCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CurrencyResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.CurrencyResponse>;

  update(
    request: protobuf_currency_currency_pb.UpdateCurrencyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.CurrencyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency.CurrencyService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency.CurrencyService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/currency.CurrencyService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_currency_currency_pb.GetCurrenciesRequest,
    protobuf_currency_currency_pb.GetCurrenciesResponse,
    (request: protobuf_currency_currency_pb.GetCurrenciesRequest) => {
      return request.serializeBinary();
    },
    protobuf_currency_currency_pb.GetCurrenciesResponse.deserializeBinary
  );

  get(
    request: protobuf_currency_currency_pb.GetCurrenciesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_currency_currency_pb.GetCurrenciesResponse>;

  get(
    request: protobuf_currency_currency_pb.GetCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_currency_currency_pb.GetCurrenciesResponse) => void): grpcWeb.ClientReadableStream<protobuf_currency_currency_pb.GetCurrenciesResponse>;

  get(
    request: protobuf_currency_currency_pb.GetCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_currency_currency_pb.GetCurrenciesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency.CurrencyService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency.CurrencyService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/currency.CurrencyService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_currency_currency_pb.DeleteCurrenciesRequest,
    protobuf_currency_currency_pb.DeleteCurrenciesResponse,
    (request: protobuf_currency_currency_pb.DeleteCurrenciesRequest) => {
      return request.serializeBinary();
    },
    protobuf_currency_currency_pb.DeleteCurrenciesResponse.deserializeBinary
  );

  delete(
    request: protobuf_currency_currency_pb.DeleteCurrenciesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_currency_currency_pb.DeleteCurrenciesResponse>;

  delete(
    request: protobuf_currency_currency_pb.DeleteCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_currency_currency_pb.DeleteCurrenciesResponse) => void): grpcWeb.ClientReadableStream<protobuf_currency_currency_pb.DeleteCurrenciesResponse>;

  delete(
    request: protobuf_currency_currency_pb.DeleteCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_currency_currency_pb.DeleteCurrenciesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency.CurrencyService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency.CurrencyService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/currency.CurrencyService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_currency_currency_pb.ListCurrenciesRequest,
    protobuf_currency_currency_pb.ListCurrenciesResponse,
    (request: protobuf_currency_currency_pb.ListCurrenciesRequest) => {
      return request.serializeBinary();
    },
    protobuf_currency_currency_pb.ListCurrenciesResponse.deserializeBinary
  );

  list(
    request: protobuf_currency_currency_pb.ListCurrenciesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_currency_currency_pb.ListCurrenciesResponse>;

  list(
    request: protobuf_currency_currency_pb.ListCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_currency_currency_pb.ListCurrenciesResponse) => void): grpcWeb.ClientReadableStream<protobuf_currency_currency_pb.ListCurrenciesResponse>;

  list(
    request: protobuf_currency_currency_pb.ListCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_currency_currency_pb.ListCurrenciesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency.CurrencyService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency.CurrencyService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/currency.CurrencyService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_currency_currency_pb.ExportCurrenciesRequest,
    protobuf_currency_currency_pb.ExportCurrenciesResponse,
    (request: protobuf_currency_currency_pb.ExportCurrenciesRequest) => {
      return request.serializeBinary();
    },
    protobuf_currency_currency_pb.ExportCurrenciesResponse.deserializeBinary
  );

  export(
    request: protobuf_currency_currency_pb.ExportCurrenciesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_currency_currency_pb.ExportCurrenciesResponse>;

  export(
    request: protobuf_currency_currency_pb.ExportCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_currency_currency_pb.ExportCurrenciesResponse) => void): grpcWeb.ClientReadableStream<protobuf_currency_currency_pb.ExportCurrenciesResponse>;

  export(
    request: protobuf_currency_currency_pb.ExportCurrenciesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_currency_currency_pb.ExportCurrenciesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/currency.CurrencyService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/currency.CurrencyService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

